"use client"

import { useEffect } from "react"
import { useStore } from "@/stores"
import { usePostHog } from "posthog-js/react"

export default function PosthogIdentify() {
  const posthog = usePostHog()
  const { user, member } = useStore((s) => ({ user: s.user, member: s.member }))

  useEffect(() => {
    if (!user) return
    if (user.id && posthog) {
      posthog.identify(user.id, {
        name: user.displayName,
        email: user.email,
        type: user.type,
      })
    }
  }, [user, posthog])

  useEffect(() => {
    if (!member) return
    if (member.id && posthog) {
      posthog.identify(member.id, {
        name: member.displayName,
        email: member.email,
        type: member.type,
      })
    }
  }, [member, posthog])

  return null
}
