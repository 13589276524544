"use client"

import React, { useEffect } from "react"
import { useParams, usePathname, useSearchParams } from "next/navigation"
import { env } from "@/env.mjs"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

if (typeof window !== "undefined") {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY ?? "", {
    api_host: "/ingest",
    ui_host: env.NEXT_PUBLIC_POSTHOG_UI_HOST,
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug()
    },
  })
}

export default function PostHogPageView() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const params = useParams()
  const { slug, courseKey } = params as {
    slug: string
    courseKey: string
  }

  useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname
      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`
      }
      posthog.group("brand", slug)
      posthog.group("course", courseKey)
      posthog.capture("$pageview", {
        $current_url: url,
      })
    }
  }, [pathname, searchParams, slug, courseKey])

  return null
}

export function PHProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}
