"use client"

import React, { useEffect } from "react"
import { useStore } from "@/stores"
import { type User } from "@/types"

type Props = {
  user: User | null
  member: User | null
}

export const UserStoreInitializer: React.FC<Props> = ({ user, member }) => {
  useEffect(() => {
    initializedUser.current = false
  }, [user])

  useEffect(() => {
    initializedMember.current = false
  }, [member])

  const initializedUser = React.useRef(false)
  const initializedMember = React.useRef(false)

  if (!initializedUser.current && user) {
    initializedUser.current = true
    useStore.setState({
      user,
    })
  }
  if (!initializedMember.current && member) {
    initializedMember.current = true
    useStore.setState({
      member,
    })
  }

  return null
}
